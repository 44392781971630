body{
  margin: 0;
}

.div_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: grey;
}

.div_container > button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 2px #e4e4e4;
  background-color: white;
  cursor: pointer;
  transition: 1s;
}

.div_container > button:hover {
  background-color: black;
  color: white;
}

.div_paper {
  height: 1080px;
  line-height: 130%;
  width: 735px;
  margin: 30px;
  background-color: white;
  text-align: center;
}

.div_paper > div {
  margin: 30px;
}
